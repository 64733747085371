import { linkResolver } from "./link-resolver";

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      homepage: require("../../custom_types/homepage.json"),
      page: require("../../custom_types/page.json"),
      header: require("../../custom_types/header.json"),
      footer: require("../../custom_types/footer.json"),
    },
  },
];
