import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

import { repositoryConfigs } from "./config/prismic/previews";

import "bootstrap/dist/css/bootstrap.min.css";

//import "./src/normalize.css";
//import "./src/style.css";

import "./src/stylesheets/main.scss";

//import "tailwindcss/tailwind.css";
//import "./src/index.css";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);
